<template>
  <div class="table_sty">
    <div class="froms" style="margin: 5px">
      <div>基本信息</div>
      <div class="wire"></div>

      <el-row :gutter="20">
        <el-col :span="24">
          <div class="message">
            <p>事件标题：</p>
            <p class="p1">{{ data.name }}</p>
            <div class="sign">
              <div class="sign-1" :style="{ background: data.back_color }">
                {{ data.level_type }}
              </div>
              <div
                class="sign-2"
                :style="{
                  border: `1px  solid ${data.back_color}`,
                  color: data.back_color,
                }"
              >
                {{ data.level_color }}
              </div>
            </div>
          </div>
        </el-col>
       
        <el-col :span="8">
          <div class="message">
            <p>上报人：</p>
            <p class="p1">{{ data.contact }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="message">
            <p>上报人电话：</p>
            <p class="p1">{{ data.contact_phone }}</p>
          </div>
        </el-col>

        <el-col :span="8">
          <div class="message">
            <p>日期时间：</p>
            <p class="p1">{{ data.happen_time }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="message">
            <p>事件类型：</p>
            <el-tag>{{ data.cate_name }}</el-tag>
          </div>
        </el-col>   
        <el-col :span="8">
          <div class="message">
            <p>处理状态：</p>
            <el-tag type=""  v-if="data.status==0">待处理</el-tag>
            <el-tag type=""  v-if="data.status==1">处理中</el-tag>
            <el-tag type=""  v-if="data.status==2">待验收</el-tag>
            <el-tag type=""  v-if="data.status==3">处理完成</el-tag>
            <!-- <p class="p1" v-if="data.status==0">未处理</p>
            <p class="p1" v-if="data.status==1||data.status==2">处理中</p> -->
            <!-- <p class="p1" v-if="data.status==3">处理完成</p> -->

          </div>
        </el-col>       

        <el-col :span="8">
          <div class="message">
            <p>地址：</p>
            <p class="p1">{{ data.place }}</p>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="message">
            <p>事件说明：</p>
            <p class="p1">{{ data.detail }}</p>
          </div>
        </el-col>
        <el-col :span="24" v-if="images.length != 0">
          <div class="message">
            <p>图片：</p>
            <div class="imgs">
              <div v-for="(item, index) in images" :key="index">
                <el-image :src="item" alt="" :preview-src-list="[item]" />
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="24" v-if="data.vedio">
          <div class="message">
            <p>视频：</p>

            <video
              class="upload-sty"
              controls="controls"
              :src="data.vedio"
            ></video>
          </div>
        </el-col>
        
      </el-row>
    </div>


    <div class="block">
      <!-- <div class="title">
        <p>标题：{{ info.name }}</p>
        <p>发起时间：{{ info.add_time }}</p>
        <p>状态：<el-tag v-if="info.status==1">完成</el-tag><el-tag v-else>未完成</el-tag></p>
        <p v-if="info.status==1">完成时间：{{ info.completed_time }}</p>
      </div> -->
      <el-timeline> 
        <el-timeline-item
          v-for="(item, index) in operation_records"
          :key="index"
          :timestamp="item.date"
          placement="top"
        style="padding-bottom: none;">
          <el-card>

            <h4>{{item.type_name}} : {{ item.remark }}</h4>
            <p>{{ item.nickname }} <el-tag v-if="item.result">({{item.result}})</el-tag></p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>

    <!-- <div class="froms" style="margin: 5px">
      <cases_particulars :id="id" url_type="cases" :operation_status="data.status" ref="cases_particulars" />
    </div> -->
  </div>
</template>

<script>
import { casesdetail,casesControlDetail } from "../../assets/request/api";
// import tablepop from "../../components/table_pop";
// import cases_particulars from "../../components/control/cases_particulars";

export default {
  name: "case_particulars",
  components: { },
  data() {
    return {
      id: "",
      data: {},
      images: [],
      info: {},
      operation_records: "",
    };
  },
  created() {
    let { id } = this.$route.query;
    this.id = id;
    casesdetail({ id }).then((res) => {
      this.data = res.data.data;
      if (this.data.images) {
        this.images = this.data.images.split(",");
      }
    });
    casesControlDetail({ source_id: id }).then((res) => {
          let info = res.data;
          this.info=info;
          this.operation_records = info.operation_records;
        });

  },
  methods: {},
};
</script>
<style lang="less" scoped>
.message {
  display: flex;
  font-size: 15px;
  color: #28307c;
  margin-top: 10px;
  .p1 {
    color: #465269;
  }
}
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #0788ff;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.result {
  display: flex;
  align-items: center;
  & > div {
    margin-right: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #19be6b;
  }
}
.wire {
  height: 1px;
  background: #e8eaec;
  margin: 10px -15px 0 -15px;
}
.sign {
  margin-left: 30px;
  display: flex;
  div {
    width: 30px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 9px;
    color: #ffffff;
    box-sizing: border-box;
  }
}

.sign-1 {
  border-radius: 5px 0 0 5px;
}
.sign-2 {
  border-radius: 0 5px 5px 0;
}

.imgs {
  display: flex;

  .el-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
